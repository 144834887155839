import * as React from "react";

import clsx from "clsx";

import { commonStackedGridCell } from "~styles/common/common.stacked-grid.css";

import { Box } from "~components/Box";
import { ClipPathMaskWrapper } from "~components/ClipPathMaskWrapper";
import { Grid } from "~components/Grid";
import { StoryblokEditable } from "~components/StoryblokEditable";

import {
  SequencedAnimationsContext,
  SequencedAnimationsContextProvider,
} from "~context/SequencedAnimationsContext";

import { AnimationSlider } from "../../../animations/animation_primitives/AnimationSlider/AnimationSlider";
import { AccordionRichText } from "../../../components/AccordionRichText";
import { ANIMATIONS } from "../ACCORDION_ANIMATIONS_MAP";
import { FloatingUiElements } from "../AccordionFloatingUiElements";
import { maskedWrapper } from "./styles.css";

import type { BlokWithAnimation } from "../../../animations/animation_primitives/AnimationSlider/AnimationSlider.types";
import type { AnimLayerAlertProps } from "~animations/__layers__/mock_ui_components/AnimLayerAlert";
import type { AnimLayerNotificationProps } from "~animations/__layers__/mock_ui_components/AnimLayerNotification";
import type { AccordionRichTextItemProps } from "~components/AccordionRichText/_AccordionRichTextItem";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AccordionAnimationsAddonPageItemProps
  extends BlokWithAnimation,
    Omit<AccordionRichTextItemProps, "index"> {
  animLayerNotification?: Array<StoryblokBlok & AnimLayerNotificationProps>;
  animLayerAlert?: Array<StoryblokBlok & AnimLayerAlertProps>;
}

export type AccordionAnimationsAddonPageProps = StoryblokBlok & {
  items: Array<Omit<AccordionAnimationsAddonPageItemProps, "index">>;
  shouldShowNumbers?: boolean;
};

const GRID_TEMPLATE_COLUMNS: GetSprinklesArgs["gridTemplateColumns"] = {
  mobile: 1,
  desktop: 2,
};

export function AccordionAnimationsAddonPage({
  items,
  shouldShowNumbers,
  ...rest
}: AccordionAnimationsAddonPageProps) {
  return (
    <SequencedAnimationsContextProvider length={items?.length}>
      <SequencedAnimationsContext.Consumer>
        {({ current, handleUserSetState }) => {
          return (
            <StoryblokEditable {...rest}>
              <Grid
                gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
                alignItems="center"
                position="relative"
              >
                <AccordionRichText
                  value={current?.toString()}
                  onValueChange={handleUserSetState}
                  accordionItems={items}
                  collapsible={false}
                  shouldShowNumbers={shouldShowNumbers}
                />
                {/**
                 * ------------------------------------------------------------------------------
                 * Wrapper for masked video and floating UI elements (which are positioned over mask
                 * with absolute positioning)
                 * ------------------------------------------------------------------------------
                 */}
                <Box
                  display={{ mobile: "none", desktop: "block" }}
                  width="gridSpan5"
                  marginLeft="auto"
                  position="relative"
                  aspectRatio="square"
                >
                  <ClipPathMaskWrapper
                    aspectRatio="square"
                    className={clsx(maskedWrapper)}
                    mask="rectangular"
                  >
                    <AnimationSlider
                      animationComponentMap={ANIMATIONS}
                      className={commonStackedGridCell}
                      items={items}
                    />
                  </ClipPathMaskWrapper>

                  <FloatingUiElements items={items} />
                </Box>
              </Grid>
            </StoryblokEditable>
          );
        }}
      </SequencedAnimationsContext.Consumer>
    </SequencedAnimationsContextProvider>
  );
}
