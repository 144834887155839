import * as React from "react";

import {
  commonStackedGrid,
  commonStackedGridCell,
} from "~styles/common/common.stacked-grid.css";

import { AccordionRichText } from "~components/AccordionRichText";
import { Box } from "~components/Box";
import { ClipPathMaskWrapper } from "~components/ClipPathMaskWrapper";
import { Grid } from "~components/Grid";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimatedMaskTitle } from "~animations/AnimatedMaskTitle";
import { AnimationSlider } from "~animations/animation_primitives/AnimationSlider/AnimationSlider";

import {
  SequencedAnimationsContext,
  SequencedAnimationsContextProvider,
} from "~context/SequencedAnimationsContext";

import { ANIMATIONS } from "../ACCORDION_ANIMATIONS_MAP";
import { AccordionAnimationsVideo } from "../AccordionAnimationsVideo";
import { FloatingUiElements } from "../AccordionFloatingUiElements";

import type { AnimLayerAlertProps } from "~animations/__layers__/mock_ui_components/AnimLayerAlert";
import type { AnimLayerNotificationProps } from "~animations/__layers__/mock_ui_components/AnimLayerNotification";
import type { BlokWithAnimation } from "~animations/animation_primitives/AnimationSlider/AnimationSlider.types";
import type { AccordionRichTextItemProps } from "~components/AccordionRichText/_AccordionRichTextItem";
import type { VideoProps } from "~components/Video";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { varsClipPathInset } from "~styles/vars/varsClipPathInset.css";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AccordionAnimationsHomepageItemProps
  extends BlokWithAnimation,
    Omit<AccordionRichTextItemProps, "index"> {
  animLayerNotification?: Array<StoryblokBlok & AnimLayerNotificationProps>;
  animLayerAlert?: Array<StoryblokBlok & AnimLayerAlertProps>;
  video?: Array<StoryblokBlok & VideoProps> | undefined;
  mask?: keyof typeof varsClipPathInset;
}

export type AccordionAnimationsHomepageProps = StoryblokBlok & {
  items: Array<Omit<AccordionAnimationsHomepageItemProps, "index">>;
  title?: string;
  shouldShowNumbers?: boolean;
};

const GRID_TEMPLATE_COLUMNS: GetSprinklesArgs["gridTemplateColumns"] = {
  mobile: 1,
  desktop: 2,
};

export function AccordionAnimationsHomepage({
  items,
  title,
  shouldShowNumbers,
  ...rest
}: AccordionAnimationsHomepageProps) {
  return (
    <SequencedAnimationsContextProvider length={items?.length}>
      <SequencedAnimationsContext.Consumer>
        {({ current, handleUserSetState }) => {
          const mask =
            typeof current === "number" ? items[current]?.mask : items[0]?.mask;
          // const mask =
          //   typeof current === "number" ? items[current]?.mask : "";

          return (
            <StoryblokEditable {...rest}>
              <Box>
                {title && (
                  <AnimatedMaskTitle
                    // maxWidth="gridSpan8"
                    text={title}
                    marginBottom="headerHeight"
                  />
                )}
                <Grid
                  gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
                  alignItems="center"
                  position="relative"
                >
                  {/* <ProgressIndicator
                    __left={calc.multiply(vars.spacing.spacing6, -1)}
                    __top={calc.multiply(vars.spacing.spacing3, -1)}
                    width="spacing6"
                    height="spacing6"
                    position="absolute"
                  /> */}
                  <AccordionRichText
                    value={current?.toString()}
                    onValueChange={handleUserSetState}
                    accordionItems={items}
                    collapsible={false}
                    shouldShowNumbers={shouldShowNumbers}
                  />
                  {/**
                   * ------------------------------------------------------------------------------
                   * Wrapper for masked video and floating UI elements (which are positioned over mask
                   * with absolute positioning)
                   * ------------------------------------------------------------------------------
                   */}
                  <Box
                    display={{ mobile: "none", desktop: "block" }}
                    width="gridSpan5"
                    marginLeft="auto"
                    position="relative"
                    aspectRatio="square"
                  >
                    <ClipPathMaskWrapper
                      className={commonStackedGrid}
                      mask={mask}
                    >
                      <AccordionAnimationsVideo
                        marginX="auto"
                        items={items}
                        current={current}
                        className={commonStackedGridCell}
                      />
                      <AnimationSlider
                        animationComponentMap={ANIMATIONS}
                        className={commonStackedGridCell}
                        items={items}
                        current={current}
                      />
                    </ClipPathMaskWrapper>

                    <FloatingUiElements items={items} />
                  </Box>
                </Grid>
              </Box>
            </StoryblokEditable>
          );
        }}
      </SequencedAnimationsContext.Consumer>
    </SequencedAnimationsContextProvider>
  );
}
